import React from 'react'
import { Link } from 'gatsby'
import { v4 } from 'uuid'
import * as Scroll from 'react-scroll'
import {
  Link as scrollLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from 'react-scroll'
import logo from '../../img/ges-logo.svg'
import './Navbar.scss'

export const navLinks = [
  { to: '/', text: 'Home' },
  { to: 'services', text: 'Services' },
  { to: 'projects', text: 'Projects' },
  { to: 'testimonials', text: 'Testimonials' },
  { to: 'about', text: 'About' },
  { to: 'contact', text: 'Contact', type: 'button' },
]

class Navbar extends React.Component {
  constructor(props) {
    super(props)
    this.menuToggle = this.menuToggle.bind(this)
    this.scrollToSection = this.scrollToSection.bind(this)
  }

  menuToggle(e) {
    console.log(e)
    e.preventDefault()

    const updatedState = !this.props.menuOpen
    this.props.onMenuStatusChange(updatedState)
  }

  scrollToSection(e, id) {
    e.preventDefault()
    if (id === '/') {
      scroll.scrollTo(0)
    } else {
      scroll.scrollTo(document.getElementById(id).offsetTop - 80)
    }

    const updatedState = !this.props.menuOpen
    this.props.onMenuStatusChange(updatedState)
  }

  render() {
    return (
      <nav className="navbar is-fixed-top is-transparent">
        <div className="container">
          <div className="navbar-brand">
            <Link to="/" className="navbar-item">
              <figure className="image">
                <img
                  src={logo}
                  alt="Kaldi"
                  style={{ width: '95px', height: '76px' }}
                />
              </figure>
            </Link>
            <a
              role="button"
              onClick={e => this.menuToggle(e)}
              className={`navbar-burger ${
                this.props.menuOpen ? 'is-active' : ''
              }`}
              aria-label="menu"
              aria-expanded="false"
            >
              <span aria-hidden="true" />
              <span aria-hidden="true" />
              <span aria-hidden="true" />
            </a>
          </div>
          <div
            className={`navbar-menu ${this.props.menuOpen ? 'is-active' : ''}`}
          >
            <div className="navbar-end">
              {navLinks.map(link => {
                if (link.type === 'button') {
                  return (
                    <a
                      onClick={e => this.scrollToSection(e, link.to)}
                      className="navbar-item is-contact-button"
                      key={v4()}
                    >
                      <span className="button is-dark">{link.text}</span>
                    </a>
                  )
                }
                return (
                  <a
                    onClick={e => this.scrollToSection(e, link.to)}
                    className="navbar-item"
                    key={v4()}
                  >
                    {link.text}
                  </a>
                )
              })}
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default Navbar
