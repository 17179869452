import React from 'react'
import Navbar from './Navbar/Navbar'
import Footer from './Footer/Footer'
import '../styles/all.scss'

export default class TemplateWrapper extends React.Component {
  constructor(props) {
    super(props)
    this.state = { mobileMenuOpen: false }
    this.onMenuStatusChange = this.onMenuStatusChange.bind(this)
  }

  onMenuStatusChange(status) {
    this.setState(state => ({
      mobileMenuOpen: status,
    }))
  }

  render() {
    const { children } = this.props
    return (
      <div
        className={`menu-wrapper ${
          this.state.mobileMenuOpen ? ' mobile-menu-open' : ''
        }`}
      >
        <Navbar
          menuOpen={this.state.mobileMenuOpen}
          onMenuStatusChange={this.onMenuStatusChange}
        />
        <div>{children}</div>
        <Footer />
      </div>
    )
  }
}
