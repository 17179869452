import React from 'react'
import { Link } from 'gatsby'
import { v4 } from 'uuid'
import * as Scroll from 'react-scroll'
import {
  Link as scrollLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from 'react-scroll'
import { navLinks } from '../Navbar/Navbar'
import './Footer.scss'
import FooterLogo from '../../img/footer-logo.png'
import FacebookIcon from '../../img/social-icons/fb.png'
import LinkedInIcon from '../../img/social-icons/in.png'

function scrollToSection(e, id) {
  e.preventDefault()
  if (id === '/') {
    scroll.scrollTo(0)
  } else {
    scroll.scrollTo(document.getElementById(id).offsetTop - 80)
  }
}

const FooterLinks = () => {
  const links = navLinks.map(link => (
    <li key={v4()}>
      <a onClick={e => scrollToSection(e, link.to)} key={v4()}>
        {link.text}
      </a>
    </li>
  ))

  return <ul>{links}</ul>
}

const Footer = () => (
  <div className="footer-wrapper">
    <div className="container">
      <div className="columns">
        <div className="column is-3">
          <img src={FooterLogo} width="145" height="108" />
        </div>
        <div className="column is-1 social-icons">
          <a
            href="https://www.facebook.com/pages/GES-Creative/791858707595622?sk=timeline&amp;ref=page_internal"
            target="_blank"
          >
            <img src={FacebookIcon} width="41" height="41" alt="GES Facebook" />
          </a>
          <a
            href="https://www.linkedin.com/company/ges-creative?trk=top_nav_home"
            target="_blank"
          >
            <img src={LinkedInIcon} width="41" height="41" alt="GES LinkedIn" />
          </a>
        </div>
        <div className="column is-4">
          <FooterLinks />
        </div>
        <div className="column is-4 has-text-right-desktop">
          <p className="telephone">01423 858626</p>
          <p>
            Copyright GES Creative Design
            {` ${  new Date().getFullYear()}`}
          </p>
          <p>
            <a href="https://www.dhdesigns.uk" target="_blank">
              Designed by DH Designs
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
)

export default Footer
